// ButtonWorkFilter.tsx
import React from "react";
import { Button, Grid, useTheme } from "@mui/material";

interface ButtonWorkFilterProps {
  selectedTag: string;
  tag: string;
  handleTagSelection: (tag: string) => void;
}

const ButtonWorkFilter: React.FC<ButtonWorkFilterProps> = ({
  selectedTag,
  tag,
  handleTagSelection,
}) => {
  const theme = useTheme();
  return (
    <Grid item xs={12} sm="auto">
      <Button
        variant={selectedTag === tag ? "contained" : "text"}
        size="medium"
        onClick={() => handleTagSelection(tag)}
        sx={{
          [theme.breakpoints.up("sm")]: {
            my: 2,
          },
          color: "grey",
          fontSize: "1.5rem",
          display: "block",

          borderRadius: 0,
          fontWeight: "bold",
          backgroundColor: "white",
          "&:hover": {
            borderColor: "grey",
            borderWidth: "2px",
            backgroundColor: "transparent",
          },
        }}
      >
        {tag.charAt(0).toUpperCase() + tag.slice(1)}
      </Button>
    </Grid>
  );
};

export default ButtonWorkFilter;
