import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { TeamMemberProps } from "../types/general";

const TeamMember: React.FC<TeamMemberProps> = ({
  name,
  job,
  image,
  email,
  telefon,
}) => {
  return (
    <Card
      sx={{ maxWidth: 500, borderRadius: 0, boxShadow: "none", border: "none" }}
    >
      <CardActionArea>
        <CardMedia component="img" height="450" image={image} alt={name} />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {job}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {email}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {telefon}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TeamMember;
