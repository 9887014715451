// ClientsList.tsx
import React from "react";
import { Grid } from "@mui/material";
import ClientCard from "./ClientCard";
import clientsData from "../data/clientsData";

// Define the interface for the client object
interface Client {
  imageUrl: string;
  websiteUrl: string;
}

const ClientsList: React.FC = () => {
  return (
    <Grid container spacing={0}>
      {" "}
      {/* Set spacing to 0 to remove spaces between clients */}
      {clientsData.map((client: Client, index: number) => (
        <Grid item xs={12} sm={4} md={3} lg={2} xl={1.5} key={index}>
          {" "}
          {/* Adjust grid sizing for 8 columns on larger screens */}
          <ClientCard
            imageUrl={client.imageUrl}
            websiteUrl={client.websiteUrl}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ClientsList;
