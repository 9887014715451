// ServicesList.tsx
import React from "react";
import { Card, CardContent, List, ListItem } from "@mui/material";
import TitleH4 from "./TitleH4";
import ParagraphList from "./ParagraphList";
import servicesData from "../data/services"; // Adjust the import path as needed

const ServicesCard: React.FC = () => {
  return (
    <>
      {servicesData.map((category, index) => (
        <Card variant="outlined" key={index} sx={{ mb: 4 }}>
          <CardContent>
            <TitleH4>{category.title}</TitleH4>
            <List sx={{ color: "grey", width: "100%", textAlign: "center" }}>
              {category.services.map((service, serviceIndex) => (
                <ListItem
                  key={serviceIndex}
                  disablePadding
                  sx={{ justifyContent: "center" }}
                >
                  <ParagraphList>{service}</ParagraphList>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default ServicesCard;
