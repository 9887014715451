// useFormControls.ts
import { useState } from "react";
import { FormValues, FormErrors } from "../types/general"; // Import the types
import { PostContactForm } from "../hooks/useFormControls"; // Import the API function

const initialFormValues: FormValues = {
  name: "",
  email: "",
  message: "",
  formSubmitted: false,
  success: false,
};

export const useFormControls = () => {
  const [values, setValues] = useState<FormValues>(initialFormValues);
  const [errors, setErrors] = useState<FormErrors>({});

  const resetForm = () => {
    setValues(initialFormValues);
  };

  const validate = (fieldValues: Partial<FormValues> = values) => {
    let temp: FormErrors = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required.";

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : "This field is required.";
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ""
          : "Email is not valid.";
    }

    if ("message" in fieldValues)
      temp.message =
        fieldValues.message && fieldValues.message.length !== 0
          ? ""
          : "This field is required.";

    setErrors({
      ...temp,
    });
  };

  const handleInputValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value } as Partial<FormValues>);
  };

  const handleSuccess = (closeModalCallback: () => void) => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: true,
    });
    closeModalCallback();
  };

  const handleError = () => {
    setValues({
      ...initialFormValues,
      formSubmitted: true,
      success: false,
    });
  };

  const formIsValid = (fieldValues: FormValues = values) => {
    const isValid =
      fieldValues.name &&
      fieldValues.email &&
      fieldValues.message &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  const handleFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    closeModalCallback: () => void
  ) => {
    e.preventDefault();
    if (formIsValid()) {
      // Replace this with your actual email sending logic
      await PostContactForm(
        values,
        () => handleSuccess(closeModalCallback),
        handleError
      );
    }
  };

  return {
    values,
    errors,
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    resetForm,
  };
};
