// servicesData.ts
const servicesData = [
  {
    title: "PRE-DESIGN PHASE",
    services: [
      "Vision and Strategy Planning",
      "Feasibility Analysis for construction projects",
      "Site Selection, identifying optimal building locations",
      "Site Analysis, conducting detailed studies of sites",
      "Cost Analysis",
      "Site Surveys: Boundary & Topographical Surveys",
    ],
  },
  {
    title: "DESIGN SOLUTIONS",
    services: [
      "Architectural Design",
      "Interior Design",
      "Landscape Design",
      "Mechanical Systems Design and Engineering",
      "Finish, Fixture, and Equipment Selections (FFE)",
      "Urban Planning & Site Design",
      "Structural Design and Engineering",
      "Lighting Design and Electrical Layout",
      "Fire Safety Scenario, Design & Fire Authorisation",
      "Civil Design and Engineering",
    ],
  },
  {
    title: "DOCUMENTATION",
    services: [
      "Permit Drawings",
      "Construction Drawings",
      "Technical Drawings",
      "Material Research",
    ],
  },
  {
    title: "PROJECT MANAGEMENT",
    services: [
      "Construction Cost Evaluation",
      "Contractor Evaluation",
      "City Building Permitting Review",
      "Value Engineering",
      "Construction Observation",
    ],
  },
  {
    title: "OTHER SERVICES",
    services: [
      "Business Consultancy",
      "Financial Planning",
      "Legal & Accounting Services",
      "Value Engineering",
      "Referrals to Building Contractors and other service providers",
    ],
  },
];

export default servicesData;
