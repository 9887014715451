import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { Button, TextField, Box, Alert, Snackbar } from "@mui/material";
import { useFormControls } from "./contactFormControls";

const inputFieldValues = [
  {
    name: "name",
    label: "Full Name",
    id: "my-name",
  },
  {
    name: "email",
    label: "Email",
    id: "my-email",
  },
  {
    name: "message",
    label: "Message",
    id: "my-message",
    multiline: true,
    rows: 3,
  },
];

const ContactUs = () => {
  const { handleInputValue, formIsValid, errors, resetForm } =
    useFormControls();
  const form = useRef<HTMLFormElement>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const sendEmail = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    emailjs
      .sendForm("service_wnk7n4d", "template_xjf80jn", form.current!, {
        publicKey: "DyuqbJu5sZiWaXtoi",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          if (form.current) {
            form.current.reset();
          }
          resetForm();
          setSnackbarMessage("Your message was sent!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
          setSnackbarMessage("Failed to send message. Please try again later.");
          setSnackbarSeverity("error");
          setOpenSnackbar(true);
        }
      );
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        {inputFieldValues.map((inputFieldValue, index) => {
          return (
            <TextField
              variant="outlined"
              key={index}
              onChange={handleInputValue}
              onBlur={handleInputValue}
              name={inputFieldValue.name}
              label={inputFieldValue.label}
              error={!!errors[inputFieldValue.name]}
              multiline={inputFieldValue.multiline ?? false}
              fullWidth
              rows={inputFieldValue.rows ?? 1}
              autoComplete="none"
              sx={{ marginBottom: 2 }} // Add bottom margin to each TextField
              {...(errors[inputFieldValue.name] && {
                error: true,
                helperText: errors[inputFieldValue.name],
              })}
            />
          );
        })}
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            disabled={!formIsValid()}
            value="Send"
            variant="outlined"
            size="large"
            type="submit"
            sx={{
              color: "black",
              borderColor: "black",
              borderWidth: "2px",
              borderRadius: 0,
              fontWeight: "bold",
              "&:hover": {
                borderColor: "black",
                backgroundColor: "transparent",
              },
            }}
          >
            CONTACT US
          </Button>
        </Box>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          top: "50%", // Center vertically
          left: "50%", // Center horizontally
          transform: "translate(-50%, -50%)", // Adjust position to truly center
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUs;
