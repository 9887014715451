import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery"; // Import useMediaQuery
import { useTheme } from "@mui/material/styles"; // Import useTheme
import { useEffect } from "react";

import logoTriptic from "../assets/logoTriptic.png";

const pages = ["work", "office", "contact"];
const subPages = [
  "Profile",
  "Services",
  "Employment",
  "People",
  "Publications",
  "Awards",
  "Clients",
];

const Header = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [isNavOpen, setIsNavOpen] = React.useState(false); // State to track navbar open status
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleToggleNavMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
    setIsNavOpen(true); // Set navbar as open
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setIsNavOpen(false); // Set navbar as closed
  };

  useEffect(() => {
    const body = document.body;
    body.style.overflow = isNavOpen ? "hidden" : "";
  }, [isNavOpen]);

  const isActiveLink = (page: any) => {
    return location.pathname === `/${page}`;
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
          border: "none",
          pt: 3,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            {/* Logo Box for desktop */}
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <Box
                  component="img"
                  src={logoTriptic}
                  alt="TRIPTIC Logo"
                  sx={{
                    flexGrow: 1,
                    maxWidth: "200px", // Adjust the size as needed
                    display: { xs: "none", md: "flex" }, // Responsive display using the sx prop
                    // Add any additional styles you need here
                  }}
                />
              </Link>
            </Box>

            {/* Logo Box for mobile */}
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                alignItems: "center",
              }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Box
                  component="img"
                  src={logoTriptic}
                  alt="TRIPTIC Logo"
                  sx={{
                    maxWidth: "130px", // Adjust the size as needed
                  }}
                />
              </Link>
            </Box>

            {/* Burger Menu for mobile */}
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ color: "black" }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "flex-end",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "grey", display: "block" }}
                  component={Link}
                  to={`/${page}`}
                >
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "1.75rem" }} // Make text bold and slightly bigger
                  >
                    {page}
                  </Typography>
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {location.pathname === "/office" && (
        <Container
          maxWidth="xl"
          sx={{
            display: { xs: "none", md: "block" }, // Hide on xs screens, show on md screens and up
          }}
        >
          <Toolbar disableGutters>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "white",
                display: { xs: "none", md: "flex" }, // Hide on xs screens, show on md screens and up
                justifyContent: "flex-end",
                alignItems: "flex-start", // Align items to the top
                //py: 1,
              }}
            >
              {subPages.map((subPage) => (
                <Button
                  key={subPage}
                  component="a"
                  href={`#${subPage}`} // Use anchor links
                  sx={{
                    //mx: 2,
                    color: "grey",
                    fontWeight: "bold",
                    fontSize: "1.25rem",
                  }} // Smaller text size
                >
                  {subPage}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      )}
      {isMobile && isNavOpen && (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "white",
            zIndex: theme.zIndex.modal,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              px: 2,
              py: 1,
            }}
          >
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                pt: 3,
              }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Box
                  component="img"
                  src={logoTriptic}
                  alt="TRIPTIC Logo"
                  sx={{
                    maxWidth: "130px", // Adjust the size as needed
                  }}
                />
              </Link>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              p: 1,
              pt: 3,
            }}
          >
            <IconButton
              size="large"
              aria-label="close drawer"
              onClick={handleToggleNavMenu}
              color="inherit"
              sx={{ color: "black" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {pages.map((page) => (
            <Button
              key={page}
              onClick={handleToggleNavMenu}
              component={Link}
              to={`/${page}`}
              sx={{
                my: 2,
                fontFamily: "monospace",
                fontWeight: 700,
                fontSize: "40px",
                letterSpacing: "0px",
                textTransform: "uppercase",
                lineHeight: "100%",
                color: isActiveLink(page) ? "black" : "#888", // Active link is black, others are #888
                textDecoration: "none",
                transition: "color .24s ease-out",
                "&:hover": {
                  color: "black", // Hover color is black
                },
              }}
            >
              {page}
            </Button>
          ))}
        </Box>
      )}
    </>
  );
};

export default Header;
