// GuidesList.tsx
import React from "react";
import { Grid } from "@mui/material";
import GuideCard from "./GuideCard";
import guides from "../data/guides";

const GuidesList: React.FC = () => {
  return (
    <Grid container>
      {guides.map((guide, index) => (
        <GuideCard
          key={index}
          name={guide.name}
          imageUrl={guide.imageUrl}
          title={guide.title}
          description={guide.description}
          guideUrl={guide.guideUrl}
          index={index} // Pass the index to the GuideCard
        />
      ))}
    </Grid>
  );
};

export default GuidesList;
