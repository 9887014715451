import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/home";
import Work from "./pages/work";
import Office from "./pages/office";
import Project from "./pages/projectScreen";
import Contact from "./pages/contact";
import WorkingAtTriptic from "./pages/working-at-triptic";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<Home />}></Route>
      <Route path="/work" element={<Work />}></Route>
      <Route path="/office" element={<Office />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/work/:url" element={<Project />}></Route>
      <Route path="/working-at-triptic" element={<WorkingAtTriptic />}></Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
