import { ProjectProps } from "../types/general";
import { Link } from "react-router-dom";
import * as React from "react";

import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import Box from "@mui/material/Box";

import { styled } from "@mui/material/styles";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.4,
    },
    "& .MuiTypography-root": {
      opacity: 1,
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
  transition: theme.transitions.create("opacity"),
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0, // Set initial opacity to 0
  transition: theme.transitions.create("opacity"),
}));

const Project: React.FC<ProjectProps> = ({ project }) => {
  //const classes = useStyles();
  return (
    <>
      <Box
        sx={{ display: "flex", flexWrap: "wrap", minWidth: 300, width: "100%" }}
      >
        <Link
          to={`/work/${project.url}`}
          style={{ textDecoration: "none", width: "100%" }}
        >
          <ImageButton
            focusRipple
            key={project.name}
            style={{
              width: "100%",
              height: 268,
            }}
          >
            <ImageSrc style={{ backgroundImage: `url(${project.image})` }} />
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <Typography
                component="span"
                variant="subtitle1"
                color="inherit"
                sx={{
                  position: "relative",
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  opacity: 0, // Hide the project name initially
                  transition: "opacity 0.3s ease", // Smooth transition for opacity
                  fontSize: 35,
                  fontWeight: "bold",
                }}
              >
                {project.name}
              </Typography>
            </Image>
          </ImageButton>
        </Link>
      </Box>
    </>
  );
};

export default Project;
