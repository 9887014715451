import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { IconButton } from "@mui/material";
import { getRandomImages } from "../utils/functions";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CarouselHomepage = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const randomImages = getRandomImages(10);
  const maxSteps = randomImages.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: "100%", flexGrow: 1, position: "relative" }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={3000} // Increase time to 5000ms (5 seconds) before changing the image
        springConfig={{
          duration: "0.5s", // Duration of the spring animation
          easeFunction: "ease-in-out", // Easing function for the animation
          delay: "0s", // Delay before the animation starts
        }}
        animateTransitions
      >
        {randomImages.map((image, index) => (
          <div
            key={image.label}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              component="img"
              sx={{
                height: "100%",
                display: "block",
                maxWidth: "100%",
                overflow: "hidden",
                width: "100%",
                opacity: activeStep === index ? 1 : 0, // Only show the active image
                transition: "opacity 0.5s ease-in-out", // Fade transition effect
              }}
              src={image.imgPath}
              alt={image.label}
            />
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: 0,
          right: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 16px",
        }}
      >
        <IconButton
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ color: "white" }}
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          sx={{ color: "white" }}
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={<div />}
        backButton={<div />}
      />
    </Box>
  );
};

export default CarouselHomepage;
