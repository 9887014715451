import React from "react";
import { useParams } from "react-router-dom";

import projects from "../data/projects";
import { ProjectProps } from "../types/general";
import ImageCarousel from "../components/imageCarousel";
import { Box, Grid, Typography } from "@mui/material";

const ProjectScreen = () => {
  //const { id: ProjectId } = useParams();
  const { url } = useParams(); // Destructure `url` from the URL parameters
  const projectData = projects.find((p: any) => p.url === url);
  const project: ProjectProps | undefined = projectData
    ? { project: projectData }
    : undefined;
  console.log(project);

  return (
    <>
      {project && (
        <>
          <ImageCarousel projectImages={project.project.carousel} />
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                {/* Left column (empty) */}
              </Grid>

              <Grid item xs={12} md={6}>
                <h2>{project.project.name}</h2>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="justify"
                  sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
                >
                  {project.project.description}
                </Typography>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="justify"
                  sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
                ></Typography>
                <Grid spacing={2}>
                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Location
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        {project.project.location}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Client
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        {project.project.client}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Function
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        {project.project.function}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Project Phase
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        {project.project.projectPhase}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        {project.project.status}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Program Area
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        {project.project.programArea}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} sx={{ pt: 4 }}>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 700,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        Year
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "grey",
                          fontWeight: 400,
                          textAlign: { xs: "center", sm: "left" },
                        }}
                      >
                        {project.project.year}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                {/* Right column (empty) */}
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

export default ProjectScreen;
