import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box textAlign="center" mt={6} py={6}>
      <Typography variant="h6" color="textPrimary">
        Copyright © {currentYear} | All rights reserved | Privacy Policy
      </Typography>
      <Box mt={1}>
        <Link
          href="https://www.linkedin.com/company/triptic-architecture-engineering/"
          target="_blank"
          rel="noopener"
        >
          <IconButton aria-label="linkedin">
            <LinkedInIcon />
          </IconButton>
        </Link>
        {/* <Link href="https://www.facebook.com" target="_blank" rel="noopener">
          <IconButton aria-label="facebook">
            <FacebookIcon />
          </IconButton>
        </Link> */}
        <Link
          href="https://www.instagram.com/tripticstudio/"
          target="_blank"
          rel="noopener"
        >
          <IconButton aria-label="instagram">
            <InstagramIcon />
          </IconButton>
        </Link>
      </Box>
    </Box>
  );
};

export default Footer;
