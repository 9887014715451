import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/system";
import Fab from "@mui/material/Fab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import "./index.css";

function App() {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scrolled down
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      {" "}
      {/* Flex container */}
      <Header />
      <Box component="main" flexGrow={1} width="100%">
        {" "}
        {/* Main content with flex-grow */}
        <Box mt={4} width="100%">
          <Container maxWidth="xl">
            <Outlet />
          </Container>
        </Box>
        {isVisible && (
          <Fab
            color="default"
            size="small"
            aria-label="go up"
            style={{ position: "fixed", bottom: "20px", right: "20px" }}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <ArrowUpwardIcon />
          </Fab>
        )}
      </Box>
      <Footer />
    </Box>
  );
}

export default App;
