const guides = [
  {
    name: "Lacul Siutghiol si promenada Mamaia",
    imageUrl: require("../assets/files/01_ARH_ICS_GHID 2018_WORLD BANK_MAMAIA.png"),
    title: "Lacul Siutghiol si promenada Mamaia",
    description:
      "Din vizita si analiza la fata locului , s-a ajuns la concluzia ca din punctul de vedere al limbajului spatiului public, statiunea se imparte in 2 zone cu o legatura aproape inexistenta, segregate puternic de bulevardul Mamaia:  Promenada Marea Neagra  - la est, si Lacul Tabacariei - la Vest. Astfel se va face o scurta analiza a acestor doua zone si se vor identifica punctele cheie in vederea realizarii unei propuneri pentru un tesut unitar si coerent.",
    guideUrl: `${process.env.PUBLIC_URL}/files/01_ARH_ICS_GHID 2018_WORLD BANK_MAMAIA.pdf`,
  },
  {
    name: "Promenada Constanta",
    imageUrl: require("../assets/files/02_ARH_CT_WBG_DETALIERE_PROMENADA CONSTANTA_PREZENTARE.png"),
    title: "Promenada Constanta",
    description:
      "Se propun o serie de module standard pentru amenajarea și utilarea spațiilor de joacă, a spatiilor publice conexe și a curții școlii. Aceste module reprezintă fiecare grupă de instalații sau echipamente ce se doresc amplasate în context. În total s-au identificat 15 grupe diferite de echipamente ce au rolul de a deservi toate categoriile de vârstă (atât pentru copii cât și pentru adulți).",
    guideUrl: `${process.env.PUBLIC_URL}/files/02_ARH_CT_WBG_DETALIERE_PROMENADA CONSTANTA_PREZENTARE.pdf`,
  },
  {
    name: "Curtea scolii",
    imageUrl: require("../assets/files/03_ARH_ICS_GHID 2020_WORLD BANK_CURTEA SCOLII.png"),
    title: "Curtea scolii",
    description:
      "Se propun o serie de module standard pentru amenajarea și utilarea spațiilor de joacă, a spatiilor publice conexe și a curții școlii. Aceste module reprezintă fiecare grupă de instalații sau echipamente ce se doresc amplasate în context. În total s-au identificat 15 grupe diferite de echipamente ce au rolul de a deservi toate categoriile de vârstă (atât pentru copii cât și pentru adulți).",
    guideUrl: `${process.env.PUBLIC_URL}/files/03_ARH_ICS_GHID 2020_WORLD BANK_CURTEA SCOLII.pdf`,
  },
  {
    name: "Curtea scolii 153",
    imageUrl: require("../assets/files/04_ARH_S153_S6_GHID 2021_WORLD BANK.png"),
    title: "Curtea scolii 153",
    description:
      "Se propun o serie de module standard pentru amenajarea și utilarea spațiilor de joacă, a spatiilor publice conexe și a curții școlii. Aceste module reprezintă fiecare grupă de instalații sau echipamente ce se doresc amplasate în context. În total s-au identificat 15 grupe diferite de echipamente ce au rolul de a deservi toate categoriile de vârstă (atât pentru copii cât și pentru adulți).",
    guideUrl: `${process.env.PUBLIC_URL}/files/04_ARH_S153_S6_GHID 2021_WORLD BANK.pdf`,
  },
  {
    name: "Curtea scolii Stefan Pascu",
    imageUrl: require("../assets/files/05_ARH_CJ_GHID 2022_WORLD BANK_APAHIDA.png"),
    title: "Curtea scolii Stefan Pascu",
    description:
      "Se propun o serie de module standard pentru amenajarea și utilarea spațiilor de joacă, a spatiilor publice conexe și a curții școlii. Aceste module reprezintă fiecare grupă de instalații sau echipamente ce se doresc amplasate în context. În total s-au identificat 15 grupe diferite de echipamente ce au rolul de a deservi toate categoriile de vârstă (atât pentru copii cât și pentru adulți).",
    guideUrl: `${process.env.PUBLIC_URL}/files/05_ARH_CJ_GHID 2022_WORLD BANK_APAHIDA.pdf`,
  },
  {
    name: "Curtea scolii Stefan Pascu",
    imageUrl: require("../assets/files/07_ARH_CJ_GHID 2022_WORLD BANK_GILAU_FinalD.png"),
    title: "Curtea scolii Liceul Teoretic Gelu Voievod Gilau",
    description:
      "Se propun o serie de module standard pentru amenajarea și utilarea spațiilor de joacă, a spatiilor publice conexe și a curții școlii. Aceste module reprezintă fiecare grupă de instalații sau echipamente ce se doresc amplasate în context. În total s-au identificat 15 grupe diferite de echipamente ce au rolul de a deservi toate categoriile de vârstă (atât pentru copii cât și pentru adulți).",
    guideUrl: `${process.env.PUBLIC_URL}/files/07_ARH_CJ_GHID 2022_WORLD BANK_GILAU_FinalD.pdf`,
  },
];

export default guides;
