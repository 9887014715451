// GuideCard.tsx
import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ParagraphText from "./ParagraphText";
import TitleH4 from "./TitleH4";

interface GuideCardProps {
  name: string;
  imageUrl: string;
  title: string;
  description: string;
  guideUrl: string;
  index: number; // Accept index as a prop
}

const GuideCard: React.FC<GuideCardProps> = ({
  name,
  imageUrl,
  title,
  description,
  guideUrl,
  index,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isImageFirst = isMobile || index % 2 === 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} order={isImageFirst ? 1 : 2}>
        <Box
          component="img"
          src={imageUrl}
          alt={name}
          sx={{ width: "100%", height: "auto" }}
        />
      </Grid>
      <Grid item xs={12} md={6} order={isImageFirst ? 2 : 1}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            textAlign: "center",
            p: 4,
          }}
        >
          <TitleH4> {title}</TitleH4>
          <ParagraphText>{description}</ParagraphText>

          <a href={guideUrl} download style={{ textDecoration: "none" }}>
            <Button
              variant="outlined"
              size="large"
              sx={{
                color: "black",
                borderColor: "black",
                borderWidth: "2px",
                borderRadius: 0,
                fontWeight: "bold",
                "&:hover": {
                  borderColor: "black",
                  backgroundColor: "transparent",
                },
              }}
            >
              Download guide
            </Button>
          </a>
        </Box>
      </Grid>
    </Grid>
  );
};

export default GuideCard;
