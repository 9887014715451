// clientsData.ts
const clientsData = [
  {
    imageUrl: require("../assets/clients/1 cha.jpg"),
    websiteUrl: "https://charger.ro/",
  },
  {
    imageUrl: require("../assets/clients/2 aut.jpg"),
    websiteUrl: "https://www.autonom.ro/",
  },
  {
    imageUrl: require("../assets/clients/3 ctp.jpg"),
    websiteUrl: "https://ctp.eu/",
  },
  {
    imageUrl: require("../assets/clients/4 unt.jpg"),
    websiteUrl: "https://untold.com/ro",
  },
  {
    imageUrl: require("../assets/clients/5 fra.jpg"),
    websiteUrl: "https://www.franke.com/ro/ro/home.html",
  },
  {
    imageUrl: require("../assets/clients/6 day.jpg"),
    websiteUrl: "https://dayseaday.nl/en/",
  },
  {
    imageUrl: require("../assets/clients/7 abb.jpg"),
    websiteUrl: "https://global.abb/group/en",
  },
  {
    imageUrl: require("../assets/clients/8 yaz.jpg"),
    websiteUrl: "https://www.yazaki-europe.com/index",
  },
  {
    imageUrl: require("../assets/clients/9 lpp.jpg"),
    websiteUrl: "https://www.lpp.com/en/brands/",
  },
  {
    imageUrl: require("../assets/clients/10 fli.jpg"),
    websiteUrl: "https://flip.ro/",
  },
  {
    imageUrl: require("../assets/clients/11 apa.jpg"),
    websiteUrl: "https://apan.ro/",
  },
  {
    imageUrl: require("../assets/clients/12 ste.jpg"),
    websiteUrl: "https://www.steinweg.com/",
  },
  {
    imageUrl: require("../assets/clients/13 bio.jpg"),
    websiteUrl: "https://biochem.ro/",
  },
  {
    imageUrl: require("../assets/clients/14 lid.jpg"),
    websiteUrl: "https://www.lidl.ro/",
  },
  {
    imageUrl: require("../assets/clients/15 wbk.jpg"),
    websiteUrl: "https://www.worldbank.org/en/home",
  },
  {
    imageUrl: require("../assets/clients/16 sin.jpg"),
    websiteUrl: "https://www.sinsay.com/",
  },
  {
    imageUrl: require("../assets/clients/17 ame.jpg"),
    websiteUrl: "https://www.ameropa.com/home",
  },
  {
    imageUrl: require("../assets/clients/18 ele.jpg"),
    websiteUrl: "https://www.elemaster.com/en/locations/elemaster-romania-en/",
  },
  {
    imageUrl: require("../assets/clients/19 zac.jpg"),
    websiteUrl: "https://www.zacaria.ro/ro/",
  },
  {
    imageUrl: require("../assets/clients/20 agr.jpg"),
    websiteUrl: "https://agrococora.ro/",
  },
  {
    imageUrl: require("../assets/clients/21 rew.jpg"),
    websiteUrl: "https://www.rewe-group.com/en/",
  },
  {
    imageUrl: require("../assets/clients/22 urb.jpg"),
    websiteUrl: "https://tuborg.ro/",
  },
  {
    imageUrl: require("../assets/clients/23 mas.jpg"),
    websiteUrl: "https://www.maserati.com/ro/ro/dealers/autoitalia",
  },
  {
    imageUrl: require("../assets/clients/24 rom.jpg"),
    websiteUrl: "https://www.romarg.ro/",
  },
  {
    imageUrl: require("../assets/clients/25 vel.jpg"),
    websiteUrl: "https://www.velocita.ro/",
  },
  {
    imageUrl: require("../assets/clients/26 cbr.jpg"),
    websiteUrl: "https://www.cbre.om/",
  },
  {
    imageUrl: require("../assets/clients/27 tec.jpg"),
    websiteUrl: "https://techventures.bank/",
  },
  {
    imageUrl: require("../assets/clients/28 sky.jpg"),
    websiteUrl: "https://skylux.ro/",
  },
  {
    imageUrl: require("../assets/clients/29 upb.jpg"),
    websiteUrl: "https://upb.ro/",
  },
  {
    imageUrl: require("../assets/clients/30 tir.jpg"),
    websiteUrl: "https://www.tiriacauto.ro/",
  },
  {
    imageUrl: require("../assets/clients/31 pbv.jpg"),
    websiteUrl: "https://www.brasovcity.ro/",
  },
  {
    imageUrl: require("../assets/clients/32 fri.jpg"),
    websiteUrl: "https://frisomat.com/ro",
  },
];

export default clientsData;
