import React from "react";
import { Box, Card, CardMedia, Link } from "@mui/material";

interface ClientCardProps {
  imageUrl: string;
  websiteUrl: string;
}

const ClientCard: React.FC<ClientCardProps> = ({ imageUrl, websiteUrl }) => {
  return (
    <Card sx={{ maxWidth: "100%", m: "auto" }}>
      <Link href={websiteUrl} target="_blank" rel="noopener noreferrer">
        <CardMedia component="img" image={imageUrl} alt={websiteUrl} />
      </Link>
    </Card>
  );
};

export default ClientCard;
