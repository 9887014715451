import React, { useState } from "react";
import projects from "../data/projects";
import Project from "../components/Project";
import {
  Grid,
  Button,
  Select,
  FormControl,
  SelectChangeEvent,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ButtonWorkFilter from "../components/ButtonWorkFilter";

const Work = () => {
  const [selectedTag, setSelectedTag] = useState("all");
  const theme = useTheme(); // Get the theme object
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleTagSelection = (tag: string) => {
    setSelectedTag(tag);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    setSelectedTag(event.target.value);
  };

  const filteredProjects =
    selectedTag === "all"
      ? projects
      : projects.filter((project) => project.tag === selectedTag);

  const buttonStates = ["all", "public", "private"];
  return (
    <>
      <Grid
        container
        direction={isMobile ? "column" : "row"}
        justifyContent="center"
        alignItems="center"
        spacing={1}
        color="inherit"
        style={{ marginBottom: "40px" }}
      >
        {buttonStates.map((tag) => (
          <ButtonWorkFilter
            key={tag}
            selectedTag={selectedTag}
            tag={tag}
            handleTagSelection={handleTagSelection}
          />
        ))}
      </Grid>

      <Grid container spacing={3} className="mt-4 pt-4">
        {filteredProjects.map((project) => (
          <Grid item key={project._id} xs={12} sm={6} md={4}>
            <Project project={project} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Work;
