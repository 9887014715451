// useFormControls.ts
import { useState } from "react";
import { FormValues, FormErrors } from "../types/general"; // Import the types

// ... (other code)

// Define the function that will handle the form submission
export const PostContactForm = async (
  values: FormValues,
  successCallback: () => void,
  errorCallback: () => void
) => {
  try {
    // Replace 'http://localhost:3001/send-email' with the actual endpoint of your backend API
    const response = await fetch("http://localhost:3001/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    });

    if (response.ok) {
      successCallback();
    } else {
      errorCallback();
    }
  } catch (error) {
    errorCallback();
  }
};

// ... (rest of the useFormControls hook)
