import images from "../data/projectImages"; // Adjust the import path as needed

export const getRandomImages = (count: number) => {
  // Extract the arrays of images and flatten them into a single array
  const allImages = Object.values(images).flat();
  console.log("All images:", allImages); // Log all images

  // Shuffle the array
  const shuffledImages = allImages.sort(() => 0.5 - Math.random());
  console.log("Shuffled images:", shuffledImages); // Log shuffled images

  // Get the first 'count' images
  const selectedImages = shuffledImages.slice(0, count).map((image) => ({
    label: image.split("/").pop(), // Use the image filename as the label
    imgPath: image, // Use the image path directly
  }));
  console.log("Selected images:", selectedImages); // Log selected images

  return selectedImages;
};
