import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, {
  AccordionProps,
  AccordionSlots,
} from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Banner from "../components/banner";
import banners from "../data/banners";

import { Box, Grid, Typography } from "@mui/material";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function WorkingAtTriptic() {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <div>
      <Box id={banners.employment.title} sx={{ my: 4 }}>
        <Banner
          imageUrl={banners.employment.image}
          text={banners.employment.title}
        />
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            {/* Left column (empty) */}
          </Grid>

          <Grid item xs={6}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography variant="h6">
                  What is the working language?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  gutterBottom
                  align="justify"
                  sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
                >
                  Everyone in the office is fluent in English and in at least
                  one other language.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Typography variant="h6">
                  Do you encourage remote working or work-from-home?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  gutterBottom
                  align="justify"
                  sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
                >
                  We are a Windows-based office environment and work with a long
                  list of software depending on your job description.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Typography variant="h6">What software do you use?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body2"
                  gutterBottom
                  align="justify"
                  sx={{ lineHeight: "1.8", color: "grey", fontWeight: 400 }}
                >
                  The new housing product, Biome, represents a bold departure
                  from traditional construction methods, combining CREE
                  Buildings' proven modular timber-hybrid building system with
                  EFFEKT's bespoke design solutions. The result? A novel
                  approach to personalized and planet-conscious urban
                  living.≈numquam dignissimos laborum fugiat deleniti? Eum quasi
                  quidem quibusdam.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={3}>
            {/* Right column (empty) */}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
