import adrian from "../assets/team/adrian.png";
import aurel from "../assets/team/aurel.png";
import cristian from "../assets/team/cristian.png";

const team = [
  {
    _id: "1",
    name: "Aurel Basuc ",
    job: "Founding Partner, Senior Architect, MArch",
    image: aurel,
    telefon: "+40 730 617 496",
    email: "aurel@tripticstudio.ro",
  },
  {
    _id: "2",
    name: "Adrian Tutuianu",
    job: "Founding Partner, Senior Architect, MArch",
    image: adrian,
    telefon: "+40 732 771 959",
    email: "adrian@tripticstudio.ro",
  },
  {
    _id: "3",
    name: "Cristian Danciu ",
    job: "Founding Partner Business Manager",
    image: cristian,
    telefon: "+40 721 077 733",
    email: "cristian@tripticstudio.ro",
  },
];

export default team;
